/**
 * TODO
 * - some paddings and margins can be unified
 * - some parts of this component can be applied in others OfferCards
 */
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Icon from 'shopper/components/Icon';
import Price from 'shopper/components/Price';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useExclusiveOffer from 'hooks/useExclusiveOffer';
import useMediaQuery from 'hooks/useMediaQuery';

import { sendEvent } from 'lib/gtag';
import { getOfferImageLink, toOfferImageAltText } from 'lib/image';
import { toOfferLink } from 'lib/links';
import {
  hasBadge,
  isInternationalOfferByOfferTags,
  toFormattedOfferOldPrice,
  toFormattedOfferPrice,
} from 'lib/offer';
import { noop } from 'lib/utils';

import { useUser } from 'providers/UserProvider';

import OFFER from 'constants/offer';

const OfferCardFiltersDropdown = dynamic(
  () => import('../FiltersDropdown/DynamicallyLoadedFiltersDropdown')
);
const OfferCardBadges = dynamic(() => import('../Badges'));
const OfferGridCardFooter = dynamic(() => import('./Footer'));
const OfferCardTags = dynamic(() => import('../Tags'));

const OfferGridCard = ({
  authors = [],
  cardTitleAs = 'span',
  categoryId,
  categoryName = null,
  className,
  gaEventCategory,
  likesCount,
  offerComments,
  offerId,
  offerImagePriority = false,
  offerIsHighlight,
  offerOldPrice = null,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerPublished,
  offerSlug,
  offerStatusName,
  offerTags = [],
  offerTitle,
  offerUserVisibility,
  showFilterCategoriesDropdown = false,
  showFooter,
  storeDomain,
  storeName,
  subcategoryId = null,
  subcategoryName = null,
  userId,
  userName,
  userPhoto,
  onImageClick = noop,
  onTitleClick = noop,
}) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const { isOfferBlocked, onUnloggedUserTriesOpenExclusiveOffer } =
    useExclusiveOffer();
  const { isLoggedIn } = useUser();
  const { isLg } = useMediaQuery({ shouldUpdateAtInitialLoad: false });

  const onMouseOver = () => {
    if (isLg) setMouseOver(true);
  };

  const onMouseOut = () => {
    if (isLg) setMouseOver(false);
  };

  const onClick = (event) => {
    sendEvent({
      action: 'click__offer__exclusive',
      category: gaEventCategory,
      logged: isLoggedIn,
    });

    if (!isOfferBlocked(offerUserVisibility)) {
      return;
    }

    event.preventDefault();
    onUnloggedUserTriesOpenExclusiveOffer(offerSlug);
  };

  return (
    <>
      <NextLink href={toOfferLink(offerSlug)} prefetch={false} passHref>
        <Anchor
          className={twMerge(
            'flex h-full flex-col justify-between rounded-2',
            className
          )}
          onClick={onClick}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
        >
          <div className="relative flex size-full flex-row md:mb-1 md:flex-col">
            <div className="order-0 flex justify-center border-neutral-high-200 pl-2 pt-4 dark:border-neutral-low-300 md:order-unset md:w-full md:border-b md:pr-4 lg:pl-4 lg:pt-2">
              {hasBadge({
                offerIsHighlight,
                offerStatusName,
                offerUserVisibility,
              }) && (
                <OfferCardBadges
                  className="left-1 top-2 z-10 md:top-auto lg:left-4"
                  offerIsHighlight={offerIsHighlight}
                  offerStatusName={offerStatusName}
                  offerUserVisibility={offerUserVisibility}
                />
              )}
              {showFilterCategoriesDropdown && (
                <OfferCardFiltersDropdown
                  buttonVisibilityClassName={!isMouseOver && 'md:invisible'}
                  categoryId={categoryId}
                  categoryName={categoryName}
                  className="absolute right-2 top-2 z-10"
                  gaEventCategory={gaEventCategory}
                  isInternationalOffer={isInternationalOfferByOfferTags(
                    offerTags
                  )}
                  isParentFocused={isMouseOver}
                  subcategoryId={subcategoryId}
                  subcategoryName={subcategoryName}
                />
              )}
              <Image
                alt={toOfferImageAltText(offerTitle)}
                className={twJoin(
                  'size-[120px] rounded-4 md:size-[200px]',
                  offerStatusName === OFFER.STATUSES.FINISHED && 'opacity-30'
                )}
                grayscale={offerStatusName === OFFER.STATUSES.FINISHED}
                height={120}
                loading={offerImagePriority ? null : 'lazy'}
                priority={offerImagePriority}
                sizes="(max-width: 768px) 120px, 200px"
                src={getOfferImageLink(offerPhoto, 120)}
                srcSet={`
            ${getOfferImageLink(offerPhoto, 180)} 180w,
            ${getOfferImageLink(offerPhoto, 300)} 300w,
          `}
                width={120}
                onClick={onImageClick}
              />
            </div>
            <div className="flex size-full flex-col justify-between pl-4 pr-2 pt-4 md:mb-2 md:mt-2 md:pl-2 md:pr-3 md:pt-0 lg:px-4">
              <Price
                className={twJoin(
                  'order-2 mt-2 md:order-unset',
                  offerTags?.length === 0 && 'md:mb-7'
                )}
                finished={offerStatusName === OFFER.STATUSES.FINISHED}
                primaryInfo={toFormattedOfferPrice(offerPrice, offerPriceType)}
                secondaryInfo={toFormattedOfferOldPrice(
                  offerOldPrice,
                  offerPriceType
                )}
                size="size3"
              />
              {offerTags.length > 0 && (
                <OfferCardTags
                  className="order-1 mt-1 md:order-unset md:flex"
                  offerTags={offerTags}
                />
              )}
              <Box
                className={twJoin(
                  'order-0 relative h-12 md:order-unset md:min-h-12 lg:min-h-[2.825rem]',
                  isMouseOver
                    ? offerTags.length > 0
                      ? '-top-8'
                      : '-top-7'
                    : 'top-0',
                  isMouseOver ? 'overflow-y-visible' : 'overflow-y-hidden'
                )}
              >
                <Text
                  as={cardTitleAs}
                  className={twJoin(
                    'line-clamp-2 max-w-[80%] break-words dark:text-neutral-high-100 md:max-w-none',
                    isMouseOver && 'line-clamp-3'
                  )}
                  onClick={onTitleClick}
                >
                  {offerTitle}
                </Text>
              </Box>
              <Box
                className="order-4 mb-3 mt-2 flex-row justify-between md:mb-0"
                full
              >
                <Text
                  as="span"
                  className="block max-w-[65%] truncate whitespace-nowrap md:max-w-[70%]"
                  size="size2"
                  bold
                >
                  {storeName || storeDomain}
                </Text>
                <div className="flex items-center">
                  <Icon
                    className="text-neutral-low-100 dark:text-neutral-high-200"
                    name="messages"
                    size="size3"
                  />
                  <Text
                    className="ml-1 mr-2 text-neutral-low-100 dark:text-neutral-high-200 md:mr-0"
                    size="size2"
                    bold
                    suppressHydrationWarning
                  >
                    {offerComments}
                  </Text>
                </div>
              </Box>
            </div>
          </div>
          {showFooter && (
            <OfferGridCardFooter
              authors={authors}
              gaEventCategory={gaEventCategory}
              likesCount={likesCount}
              offerComments={offerComments}
              offerId={offerId}
              offerPublished={offerPublished}
              userId={userId}
              userName={userName}
              userPhoto={userPhoto}
            />
          )}
        </Anchor>
      </NextLink>
    </>
  );
};

OfferGridCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  showFilterCategoriesDropdown: PropTypes.bool,
  showFooter: PropTypes.bool.isRequired,
  storeDomain: PropTypes.string.isRequired,
  subcategoryId: PropTypes.number,
  subcategoryName: PropTypes.string,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default OfferGridCard;
