import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { toOfferLink, toRedirectAfterLoginLink } from 'lib/links';
import placeholder from 'lib/placeholder';
import { stripQs } from 'lib/request';

import { useUser } from 'providers/UserProvider';

import useDialog from './useDialog';

import OFFER from 'constants/offer';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const useExclusiveOffer = () => {
  const router = useRouter();
  const { isLoggedIn, loadingLoggedInUser } = useUser();
  const { showDialog } = useDialog();

  const isOfferBlocked = useCallback(
    (offerUserVisibility) => {
      /**
       * The visibility of `UNLOGGED_ONLY` already exists but your logic is not
       * implemented yet.
       */
      if (
        offerUserVisibility === OFFER.VISIBILITY_STATUS.ALL ||
        offerUserVisibility === OFFER.VISIBILITY_STATUS.UNLOGGED_ONLY
      ) {
        return false;
      }

      return !loadingLoggedInUser && !isLoggedIn;
    },
    [loadingLoggedInUser, isLoggedIn]
  );

  const onUnloggedUserTriesOpenExclusiveOffer = useCallback(
    (offerSlug) => {
      const currPathname = stripQs(router.asPath);
      const offerLink = toOfferLink(offerSlug);
      const isInOfferPage = currPathname === offerLink;

      if (isLoggedIn) {
        if (isInOfferPage) {
          return;
        }

        router.push(offerLink);
        return;
      }

      showDialog(LoginDialog, {
        greetingsMessage: placeholder('titles.exclusiveOfferSocialSignin'),
        onSuccess: () => {
          if (isInOfferPage) {
            return;
          }

          const urlWithRedirect = toRedirectAfterLoginLink(
            currPathname,
            toOfferLink(offerSlug)
          );

          router.push(urlWithRedirect, undefined, { shallow: true });
        },
      });
    },
    [loadingLoggedInUser, isLoggedIn, router]
  );

  return {
    isOfferBlocked,
    onUnloggedUserTriesOpenExclusiveOffer,
  };
};

export default useExclusiveOffer;
